import { template as template_7572ed5262ae4d8bafc199b1c8eb37af } from "@ember/template-compiler";
const FKLabel = template_7572ed5262ae4d8bafc199b1c8eb37af(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
