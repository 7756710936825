import { template as template_d17a6452b6c646aea0df13a26ef9420a } from "@ember/template-compiler";
const FKText = template_d17a6452b6c646aea0df13a26ef9420a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
