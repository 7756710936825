import { template as template_bdae1f04c0b845d0a4b7047c2ae63a7d } from "@ember/template-compiler";
const WelcomeHeader = template_bdae1f04c0b845d0a4b7047c2ae63a7d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
